@media (max-width: 666.98px) {
  .form-signin .login_content {
    width: 80%;
  }
}

@media (min-width: 667px) and (max-width: 767.98px) {
  .form-signin .login_content {
    width: 50%;
  }
}

@media (min-width: 667px) and (max-width: 767.98px) {
  .form-signin .login_content {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .form-signin .login_content {
    width: 50%;
  }
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .form-signin .login_content {
    width: 35%;
  }
}

@media (min-width: 1280px) {
  .form-signin .login_content {
    width: 20%;
  }
}

@media (max-width: 666.98px) {
  .overlapping-points-modal {
    min-width: 97vw !important;
  }
}

@media (max-width: 666.98px) {
  .add-surgeon-dialog {
    width: 100%;
    max-width: 96% !important;
  }
}

@media (max-width: 666.98px) {
  .surgeon-search-container {
    margin-left: 0;
  }

  .surgeon-search-container .col-md-4 {
    position: relative;
  }

  .surgeon-search-container .col-md-4 .serach-icon {
    position: absolute;
    right: -13%;
    bottom: 0;
  }
}



@media (max-width: 666.98px) {

  .responsive-ios .data-list-tabs,
  .responsive-ios .case-details-wrap,
  .responsive-ios .notifications-page {
    margin-left: 0px !important;

  }

  .responsive-ios .data-list-tabs .show-on-mobile,
  .responsive-ios .case-details-wrap .show-on-mobile,
  .responsive-ios .notifications-page .show-on-mobile {
    display: inline;
  }

  .responsive-ios .data-list-tabs .modal-content,
  .responsive-ios .case-details-wrap .modal-content,
  .responsive-ios .notifications-page .modal-content {
    width: 93%;
  }

  .responsive-ios .data-list-tabs .hide-column-on-mobile,
  .responsive-ios .data-list-tabs .hide-status-filter,
  .responsive-ios .case-details-wrap .hide-column-on-mobile,
  .responsive-ios .case-details-wrap .hide-status-filter,
  .responsive-ios .notifications-page .hide-column-on-mobile,
  .responsive-ios .notifications-page .hide-status-filter {
    display: none;
  }
}

@media only screen and (min-device-width: 666px) and (max-device-width: 768px) and (orientation: portrait) {

  .responsive-ios .hide-column-on-mobile,
  .responsive-ios .hide-status-filter,
  .responsive-ios .view-case-block {
    display: none;
  }

  .responsive-ios .responsive-main-container {
    width: 100%;
  }

  .responsive-ios .data-list-tabs {
    margin-left: -50px !important;
  }

  .responsive-ios .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios .dashboard-blocks {
    top: 0px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) and (orientation: landscape) {


  .responsive-ios .hide-column-on-mobile,
  .responsive-ios .hide-status-filter,
  .responsive-ios .view-case-block {
    display: none;
  }

  .responsive-ios .responsive-main-container {
    width: 100%;
  }

  .responsive-ios .data-list-tabs {
    margin-left: 10px !important;
  }

  .responsive-ios .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios .dashboard-blocks {
    top: 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) {

  .responsive-ios .hide-column-on-mobile,
  .responsive-ios .hide-status-filter,
  .responsive-ios .view-case-block {
    display: none;
  }

  .responsive-ios .responsive-main-container {
    width: 100%;
  }

  .responsive-ios .data-list-tabs {
    margin-left: 30px !important;
  }

  .responsive-ios .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios .dashboard-blocks {
    top: 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {

  .responsive-ios .hide-column-on-mobile,
  .responsive-ios .hide-status-filter,
  .responsive-ios .view-case-block {
    display: none;
  }

  .responsive-ios .responsive-main-container {
    width: 100%;
  }

  .responsive-ios .data-list-tabs {
    margin-left: 10px !important;
  }

  .responsive-ios .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios .dashboard-blocks {
    top: 0px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {

  .responsive-ios.ios-device .hide-column-on-mobile,
  .responsive-ios.ios-device .hide-status-filter,
  .responsive-ios.ios-device .view-case-block {
    display: none;
  }

  .responsive-ios.ios-device .responsive-main-container {
    width: 100%;
  }

  .responsive-ios.ios-device .data-list-tabs {
    margin-left: 30px !important;
  }

  .responsive-ios.ios-device .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios.ios-device .dashboard-blocks {
    top: 0px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {


  .responsive-ios.ios-device .hide-column-on-mobile,
  .responsive-ios.ios-device .hide-status-filter,
  .responsive-ios.ios-device .view-case-block {
    display: none;
  }

  .responsive-ios.ios-device .responsive-main-container {
    width: 100%;
  }

  .responsive-ios.ios-device .data-list-tabs {
    margin-left: 10px !important;
  }

  .responsive-ios.ios-device .padding-responsive {
    padding: 1rem !important;
  }

  .responsive-ios.ios-device .dashboard-blocks {
    top: 0px;
  }
}

@media (max-width: 666.98px) {
  .responsive-ios .col-md-1 {
    padding: 0;
  }

  .responsive-ios .sn-bottom-logo,
  .responsive-ios .sidenav-profile,
  .responsive-ios .sidenav {
    width: 100%;
    margin: 0 auto;
  }

  .responsive-ios .toggle-btn {
    width: 100%;
    background: white;
    margin-left: 0rem;
    margin-bottom: 1rem;
    padding: 10px 15px;
  }

  .responsive-ios .hide-sidebar {
    display: none;
  }

  .responsive-ios .show-sidebar {
    display: block;
  }

  .responsive-ios .sidemenu-wrap {
    transition: all ease-in-out 0.3s;
  }

  .responsive-ios .sidemenu-wrap .sidenav-profile {
    margin: 0;
    width: 100%;
  }

  .responsive-ios .sidemenu-wrap .sidenav-profile .profile {
    margin-left: 1em;
  }

  .responsive-ios .surgeon-info {
    margin-left: 1em;
    text-transform: capitalize;
    display: flex;
    gap: 10px;
  }

  .responsive-ios .col-md-6.logout {
    margin-top: 0;
  }

  .responsive-ios .logout-profile {
    display: flex;
    flex-direction: column-reverse;
  }

  .responsive-ios .logout#logout {
    display: flex;
    align-items: center;
  }

  .responsive-ios .role-info {
    display: block !important;
    padding: 3px 9px;
    width: -moz-max-content;
    width: max-content;
    background-color: rgba(116, 190, 162, 0.9294117647);
    color: white;
    border-radius: 10px;
    font-size: 14px;
  }
}

@media (min-width: 667px) {
  .responsive-ios .toggle-btn {
    display: none;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translateZ(0);
  }
}

@media only screen and (min-device-width: 666px) and (max-device-width: 768px) and (orientation: portrait) {
  .responsive-ios .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios .sidebar-text,
  .responsive-ios .application-logo,
  .responsive-ios .logout,
  .responsive-ios .surgeon-info,
  .responsive-ios .profile,
  .responsive-ios .sidenav-profile,
  .responsive-ios .sn-bottom-logo {
    display: none;
  }

  .responsive-ios .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios .filter-btns .text-end {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) and (orientation: landscape) {
  .responsive-ios .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios .sidebar-text,
  .responsive-ios .application-logo,
  .responsive-ios .surgeon-info,
  .responsive-ios .profile,
  .responsive-ios .sidenav-profile,
  .responsive-ios .sn-bottom-logo {
    display: none;
  }

  .responsive-ios .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios .filter-btns .text-end {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsive-ios .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios .sidebar-text,
  .responsive-ios .application-logo,
  .responsive-ios .logout,
  .responsive-ios .surgeon-info,
  .responsive-ios .profile,
  .responsive-ios .sidenav-profile,
  .responsive-ios .sn-bottom-logo {
    display: none;
  }

  .responsive-ios .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios .filter-btns .text-end {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {
  .responsive-ios .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios .sidebar-text,
  .responsive-ios .application-logo,
  .responsive-ios .logout,
  .responsive-ios .surgeon-info,
  .responsive-ios .profile,
  .responsive-ios .sidenav-profile,
  .responsive-ios .sn-bottom-logo {
    display: none;
  }

  .responsive-ios .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios .filter-btns .text-end {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .responsive-ios.ios-device .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios.ios-device .sidebar-text,
  .responsive-ios.ios-device .application-logo,
  .responsive-ios.ios-device .logout,
  .responsive-ios.ios-device .surgeon-info,
  .responsive-ios.ios-device .profile,
  .responsive-ios.ios-device .sidenav-profile,
  .responsive-ios.ios-device .sn-bottom-logo {
    display: none;
  }

  .responsive-ios.ios-device .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios.ios-device .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios.ios-device .filter-btns .text-end {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .responsive-ios.ios-device .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios.ios-device .sidebar-text,
  .responsive-ios.ios-device .application-logo,
  .responsive-ios.ios-device .logout,
  .responsive-ios.ios-device .surgeon-info,
  .responsive-ios.ios-device .profile,
  .responsive-ios.ios-device .sidenav-profile,
  .responsive-ios.ios-device .sn-bottom-logo {
    display: none;
  }

  .responsive-ios.ios-device .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios.ios-device .img-style {
    margin-left: -68px !important;
    top: 2px !important;
  }

  .responsive-ios.ios-device .filter-btns .text-end {
    margin-left: 20px;
  }
}


/* sidebar icon */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1180px) {
  .responsive-ios .logout-img-style {
    margin-left: -74px !important;
    top: -10px !important;
  }

  .responsive-ios .sidenav,
  .responsive-ios .sn-bottom-logo,
  .responsive-ios .sidenav-profile {
    width: 210px;
  }

  .responsive-ios .rar-dashboard-btn {
    margin-top: 1px;
  }
}

@media only screen and (min-width: 666px) and (max-width: 1024px) {
  .responsive-ios .sidenav ul li:not(.help-menu) a:not(.sidebar-text) {
    padding: 0px;
    height: 0;
  }

  .responsive-ios .sidenav ul li.help-menu a:not(.sidebar-text) {
    padding: 0px;
    height: 0;
  }
}

@media only screen and (min-width: 667px) and (max-width: 1023px) {
  .responsive-ios .help-icon {
    left: -40px !important;
    position: absolute;
    top: 4px !important;
    margin-left: unset !important;
    margin-top: unset !important;
  }
}


@media only screen and (min-width: 667px) and (max-width: 1023px) and (pointer: coarse) {
  .responsive-ios .help-icon {
    top: 10px !important;
    left: 6px !important;
  }
}

.responsive-ios .help-icon {
  left: -27px;
  position: absolute;
  top: 4px;
  margin-left: unset !important;
  margin-top: unset !important;
}

/* sidebar icons ends */

/* pre-op filters and download button and checkboxes on all devices */
@media (max-width: 666.98px) {

  .responsive-ios .show-on-landscape-mobile,
  .responsive-ios .status-filter {
    display: none;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .filter-btn-width {
    width: 90vw;
  }

  .responsive-ios .case-list-filter #pofilter>.filter-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .responsive-ios .case-list-filter #pofilter>.filter-options-wrapper>li {
    width: 100% !important;
    margin-left: 50px;
    padding: 0.5em;
  }
}

@media only screen and (min-device-width: 666px) and (max-device-width: 768px) and (orientation: portrait) {

  .responsive-ios ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: space-between;
  }

  .responsive-ios .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }

  .responsive-ios .case-list-filter li.hide-status-filter {
    display: none;
  }

  .responsive-ios .filter-button-margin {
    margin-left: 70%;
    margin-top: -20px;
  }

  .responsive-ios .show-on-landscape-mobile {
    display: inline;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios .res-search {
    margin-top: 7px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 768px) and (orientation: landscape) {


  .responsive-ios ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: space-between;
  }

  .responsive-ios .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }

  .responsive-ios .case-list-filter li.hide-status-filter {
    display: none;
  }

  .responsive-ios .filter-button-margin {
    margin-left: 70%;
    margin-top: -20px;
  }

  .responsive-ios .show-on-landscape-mobile {
    display: inline;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios .res-search {
    margin-top: 7px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) {

  .responsive-ios ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: space-between;
  }

  .responsive-ios .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }

  .responsive-ios .case-list-filter li.hide-status-filter {
    display: none;
  }

  .responsive-ios .filter-button-margin {
    margin-left: 70%;
    margin-top: -20px;
  }

  .responsive-ios .show-on-landscape-mobile {
    display: inline;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios .res-search {
    margin-top: 7px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {
  .responsive-ios .pagination select {
    width: 15%;
  }

  .responsive-ios ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: flex-start;
    gap: 20px;
  }

  .responsive-ios .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }

  .responsive-ios .case-list-filter li.hide-status-filter {
    display: none;
  }

  .responsive-ios .filter-button-margin {
    margin-left: 78%;
    margin-top: -20px;
  }

  .responsive-ios .mobile-ftr-btn {
    margin-left: 73.5%;
  }

  .responsive-ios .show-on-landscape-mobile {
    display: inline;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios .res-search {
    margin-top: 7px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    /*search field margin*/
    /*button flex end on  for mobile and ipad*/
  .responsive-ios.ios-device ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: flex-start;
    gap: 20px;
  }

  .responsive-ios.ios-device .filter-button-margin {
    margin-left: 78%;
    margin-top: -20px;
  }

  .responsive-ios.ios-device .show-on-landscape-mobile {
    display: inline;
  }

  .responsive-ios.ios-device .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios.ios-device .res-search {
    margin-top: 7px;
  }

  .responsive-ios.ios-device .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }
}



@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

  .responsive-ios.ios-device ul.filter-options.filter-options-wrapper.filter-op-list {
    justify-content: flex-start;
  }

  .responsive-ios.ios-device .case-list-filter ul.filter-options {
    justify-content: flex-end;
  }

  .responsive-ios.ios-device .case-list-filter li.hide-status-filter {
    display: none;
  }

  .responsive-ios.ios-device .show-on-landscape-mobile,
  .responsive-ios.ios-device .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios.ios-device .filter-btn-width {
    width: 150vw;
  }

  .responsive-ios.ios-device .res-search {
    margin-top: 7px;
  }
}

@media (max-width: 666.98px) {

  .responsive-ios .res-dropdown{
    width:79px;
    font-size: 0.7rem !important;
    padding: 0.2rem;
  }
  
  .responsive-ios .data-list-tabs .pagination {
    margin: -10px;
  }

  .responsive-ios .page-display {
    padding-top: 1px;
  }

  .responsive-ios .data-list-tabs,
  .responsive-ios .caselist-tabs {
    min-width: 100%;
  }

  .responsive-ios .file-list,
  .responsive-ios .surgeon-list,
  .responsive-ios .device-list,
  .responsive-ios .customer-list {
    background: #fff;
    border-radius: 10px;
    overflow: auto;
  }

  .responsive-ios .view-case-block {
    display: none;
  }

  .responsive-ios .file-list-heading {
    font-size: 1em;
    width: 135px;
  }

  .responsive-ios .align-items-baseline {
    flex-direction: column-reverse;
  }

  .responsive-ios .review-plan-list td *,
  .responsive-ios .preop-customer-list td * {
    width: -moz-max-content !important;
    width: max-content !important;
    font-size: 12px;
    margin: auto;
  }

  .responsive-ios .review-plan-list .zipCheckbox,
  .responsive-ios .preop-customer-list .zipCheckbox {
    width: 13px;
  }

  .responsive-ios .review-plan-list th,
  .responsive-ios .preop-customer-list th {
    font-size: 13px;
  }

  .responsive-ios .find-surgeon {
    margin-bottom: 15px;
  }

  .responsive-ios .col-width {
    width: 33px !important;
  }

  .responsive-ios .imp-col-width,
  .responsive-ios .review-plan-list .imp-col-width,
  .responsive-ios .preop-customer-list .imp-col-width {
    width: 80px !important;
  }

  .responsive-ios .plan-col-width,
  .responsive-ios .review-plan-list .plan-col-width,
  .responsive-ios .preop-customer-list .plan-col-width {
    width: 45px !important;
  }

  .responsive-ios .sur-col-width,
  .responsive-ios .review-plan-list .sur-col-width,
  .responsive-ios .preop-customer-list .sur-col-width {
    width: 52px !important;
  }
}


@media only screen and (min-device-width: 666px) and (max-device-width: 767px) and (orientation: portrait) {

  .responsive-ios .sm-col-width {
    max-width: 80%;
  }

  .responsive-ios .sm-side-width {
    max-width: 20%;
  }

  .responsive-ios .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios .show-on-mobile {
    display: block;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) and (orientation: landscape) {
  .responsive-ios .hide-column-width {
    width: 0;
    padding: 0;
    border: none;
    visibility: hidden;
  }

  .responsive-ios .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios .show-on-mobile {
    display: block;
  }

  .responsive-ios .show-column-on-mobile,
  .responsive-ios .logs-list-top-btn {
    display: inline;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) {

  .responsive-ios .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios .show-on-mobile {
    display: block;
  }
}

/* pre-op filters ends here */

/* / table hide in tablet and mobile */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {
  .responsive-ios .hide-column-width {
    width: 0;
    padding: 0;
    border: none;
    visibility: hidden;
  }

  .responsive-ios .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios .show-on-mobile {
    display: block;
  }

  .responsive-ios .show-column-on-mobile {
    display: inline;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .responsive-ios.ios-device .hide-column-width {
    width: 0;
    padding: 0;
    border: none;
    visibility: hidden;
  }

  .responsive-ios.ios-device .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios.ios-device .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios.ios-device .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios.ios-device .show-on-mobile {
    display: block;
  }

  .responsive-ios.ios-device .show-column-on-mobile {
    display: inline;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {

  .responsive-ios.ios-device .hide-column-on-mobile {
    display: none;
  }

  .responsive-ios.ios-device .logs-list-top-btn {
    display: inline;
  }

  .responsive-ios.ios-device .row {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-ios.ios-device .filter-line {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }

  .responsive-ios.ios-device .show-on-mobile {
    display: block;
  }
}

/* / table hide in tablet and mobile ends here */

/* IOS devices icons size */
.responsive-ios.ios-device .delete-icon {
  margin-left: 10px;
  max-width: 50px !important;
}

/* IOS devices icons size ends */


/* tablet sidebar menu */
@media only screen and (min-width: 666px) and (max-width: 1367px) {
  .responsive-ios.ios-device .sidenav ul li a:not(.sidebar-text) {
    padding: 0px;
    height: 0;
  }

  .responsive-ios.ios-device .help-icon {
    margin: unset;
    top: 5px !important;
    left: -40px !important;
  }
}

/* tablet sidebar menu */

/* tablet sidebar and main section */
@media only screen and (min-width: 666px) and (max-width: 768px) and (orientation: landscape) {
  .responsive-ios .sm-col-width {
    flex: 0 0 auto;
    width: 89.66666667%;
    margin-left: auto;
  }

  .responsive-ios .sm-side-width {
    flex: 0 0 auto;
    width: 8%;
  }

  .responsive-ios .col-md-11.datalist-style.mt-1.reset-temp-pass.sm-col-width {
    position: relative;
    width: 90% !important;
    left: unset !important;
    margin: auto;
  }
}

/* tablet sidebar and main section ends here */


/* CSS for the reset password page for mobile and tab */
@media only screen and (min-width: 300px) and (max-width: 768px) {
  .responsive-ios .col-md-11.datalist-style.mt-1.reset-temp-pass.sm-col-width {
    position: relative !important;
    width: 70% !important;
    left: unset !important;
    margin: auto;
  }

  .responsive-ios .reset-temp-pass .file-list-heading {
    font-size: 1.2em;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* pagination dropdown res-ui */
  .responsive-ios .pagination select {
    width: 18%;
  }

  .responsive-ios .reset-pass img.logout-img-style {
    margin-left: 0 !important;
  }

  .responsive-ios .reset-pass.col-md-1 {
    padding: 0 !important;
    width: 100px !important;
  }

  .responsive-ios .reset-pass.col-md-1 .sidemenu-wrap.hide-sidebar {
    display: block !important;
  }

  .responsive-ios .reset-pass.col-md-1 .sidenav-profile,
  .responsive-ios .reset-pass.col-md-1 .sn-bottom-logo {
    display: none;
  }

  .responsive-ios .reset-pass.col-md-1 .sidenav {
    background: #ddd;
    width: 70px;
    height: 950px;
    background-color: #ffffff;
    transition: 0.5s;
    padding-top: 25px;
    border-right: 1px solid #e0e0e0;
    margin-left: 0rem;
  }

  .responsive-ios .reset-pass.col-md-1 .show-on-mobile {
    display: block;
    list-style: none;
  }
}

@media (max-width: 768px) {
  .responsive-ios .reset-temp-pass .toggle-btn {
    width: 100%;
    background: white;
    margin-left: 0rem;
    margin-bottom: 1rem;
    padding: 10px 15px;
    display: block;
  }

  .responsive-ios .reset-temp-pass {
    flex: unset;
    width: 100%;
  }

  .responsive-ios .reset-temp-pass .sidemenu-wrap .sidenav-profile {
    display: block;
  }

  .responsive-ios .reset-temp-pass div#mySidenav {
    display: none;
  }

  .responsive-ios .reset-temp-pass .sn-bottom-logo {
    display: block;
  }
}

/*reset password screen responsive changes for dashboard */
@media screen and (max-width: 1440px){
  .responsive-ios .sm-col-width {
    left: 22.5% !important;
  }
}

@media (resolution: 1dppx){
.sm-col-width {
    left: 17.5% !important;
  }
}

/* CSS reset-pass ends here */

/*  fixed width of table in small desktop UI*/
@media screen and (min-width: 1024px) {
  .responsive-ios .file-list {
    overflow-x: auto
  }
}

@media screen and (min-width: 1023px) and (max-width: 1024px) {
  .responsive-ios.non-ios-device .show-on-mobile {
    display: none;
  }

}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .responsive-ios .table-contents {
    overflow-x: auto;
  }

  .responsive-ios .plan-col-width,
  .responsive-ios .imp-col-width {
    min-width: 100px !important;
  }
}

/* width css ends here*/